<template>
  <svg
    style="width: 24px; height: 24px; min-width: 24px; min-height: 24px"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M12 3V13.55A4 4 0 1 0 14 17V7H18V3M10 19A2 2 0 1 1 12 17A2 2 0 0 1 10 19Z"
    />
  </svg>
</template>
