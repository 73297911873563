
import { defineComponent } from 'vue';

import ParticlesBg from 'particles-bg-vue/src/particles-bg/ParticlesBg.vue';
import Navigation from '@/components/Navigation.vue';

export default defineComponent({
  name: 'App',
  components: {
    ParticlesBg,
    Navigation,
  },
});
