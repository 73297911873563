
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NavigationBar',
  setup() {
    const Links = [
      {
        title: 'About',
        url: '/about',
      },
    ];

    return {
      Links,
    };
  },
});
