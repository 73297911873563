
import { defineComponent } from 'vue';
import { useHead } from '@vueuse/head';

import Screenshots from '@/components/Screenshots.vue';

// Icons
import shieldIcon from '@/components/Icons/shield.vue';
import accountLockIcon from '@/components/Icons/accountLock.vue';
import musicNoteIcon from '@/components/Icons/musicNote.vue';
import playlistCheckIcon from '@/components/Icons/playlistCheck.vue';

export default defineComponent({
  name: 'Home',
  components: {
    Screenshots,
  },
  setup() {
    useHead({
      title: 'SongTube',
      meta: [
        {
          name: 'description',
          content: 'Official SongTube Website',
        },
      ],
    });

    const featureList = [
      {
        title: 'Ad-free, forever',
        description: 'Do anything, without any interruptions',
        icon: shieldIcon,
      },
      {
        title: 'Privacy friendly',
        description: 'Zero Google services, goodbye trackers',
        icon: accountLockIcon,
      },
      {
        title: 'Music powered',
        description: 'Audio converter & tags from MusicBrainz',
        icon: musicNoteIcon,
      },
      {
        title: 'Playlists like no other',
        description: 'Save, play and download playlists your way',
        icon: playlistCheckIcon,
      },
    ];

    return { featureList };
  },
});
